
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import FacilityEncounterComponent from "../encounter/FacilityEncounterComponent.vue";
import ProfessionalEncounterComponent from "../encounter/ProfessionalEncounterComponent.vue";
import NoteComponent from "@/modules/note/NotesComponent.vue";
import Multiselect from "@vueform/multiselect";
import { getProvider } from "@/api/provider.api";
import {
  addEncounter,
  getEncounter,
  deleteEncounter,
  updateEncounter,
  getPanel,
  postAction,
  addRawEncounter,
  updateRawEncounter,
  voidAction,
  checkEncounterTransactions,
  checkEncounterHasOpenClaims,
  checkEncounterMaxClaimAmount,
} from "@/api/encounter.api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Encounter } from "@/modules/encounter/encounter.model";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { Patient } from "../patient/patient.model";
import { searchClaims } from "@/api/claim.api";
import { useRoute, useRouter } from "vue-router";
import {
  effectivePayers,
  patientPayersByPatient,
} from "@/api/patientPayer.api";
import { searchByName } from "@/api/patient.api";
import { getDefaultFacility, getFacility } from "@/api/facility.api";
import EcnounterSavedModal from "./EcnounterSavedModal.vue";
import EncounterHistory from "./EncounterHistory.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
}

export interface PatientPayer {
  name?: string;
  payerId?: string;
  id: string | null;
  responsibility: number;
  fullName: string;
}

export interface StatusCode {
  code: string;
  description: string;
  statusFrom?: any[];
  statusFromExc?: any[];
  action?: number;
}

export interface Data {
  inProgress: boolean;
  serviceDates?: any;
  effectivePrimaryPayers?: any;
  addDisabled: boolean;
  selectedFacilityIncomplete: boolean;
  selectedFacilityProviderIncomplete?: boolean;
  selectedAttendingProviderIncomplete?: boolean;
  isDirty: boolean;
  isLoaded: boolean;
  encounter?: Encounter;
  patientId: string;
  panel: { facilities: []; patients: Patient[]; providers: [] };
  updateOnly: boolean;
  claims?: [];
  dict: {
    encounterStatusCodes: [];
    encounterActions: StatusCode[];
    submitAction: any;
    patientPayers: PatientPayer[];
  };
  mainServicePayers: {
    primaryPayer: any | null;
    secondaryPayer: any | null;
    tertiaryPayer: any | null;
  };
}

export default defineComponent({
  name: "EncounterComponent",
  components: {
    Multiselect,
    FacilityEncounterComponent,
    ProfessionalEncounterComponent,
    EcnounterSavedModal,
    EncounterHistory,
    NoteComponent,
  },
  props: ["encounter", "updateOnly", "patientId", "encounterForCopy"],
  setup(props, ctx) {
    const facilityEncounter = ref(null);
    const professionalEncounter = ref(null);
    const showModal = ref(false);

    const route = useRoute();
    const store = useStore();
    let organizationId = ref<string | null>("");
    const router = useRouter();
    let data = reactive<Data>({
      inProgress: false,
      addDisabled: false,
      isDirty: false,
      selectedFacilityIncomplete: false,
      selectedFacilityProviderIncomplete: undefined,
      selectedAttendingProviderIncomplete: undefined,
      isLoaded: false,
      encounter: undefined,
      patientId: props.patientId,
      panel: { facilities: [], patients: [], providers: [] },
      updateOnly: false,
      dict: {
        encounterStatusCodes: [],
        encounterActions: [],
        submitAction: {},
        patientPayers: [],
      },
      mainServicePayers: {
        primaryPayer: null,
        secondaryPayer: null,
        tertiaryPayer: null,
      },
    });

    const encounterRules = {
      encounter: {
        patientId: { required: helpers.withMessage("Required", required) },
        facilityId: { required: helpers.withMessage("Required", required) },
      },
    };

    const v$ = useVuelidate(encounterRules, data as never, {
      $autoDirty: false,
    });

    const breadcrumbPath = decodeURIComponent(
      String(route.query?.breadcrumb ?? "back")
    );

    watch(
      () => route.query.encounterForCopy,
      (currentValue, oldValue) => {
        ctx.emit("reload");
      }
    );

    watch(
      () => data.encounter?.patientId,
      (currentValue, oldValue) => {
        if (oldValue || !data.encounter?.encounterId) data.isDirty = true;
      }
    );

    watch(
      () => data.encounter?.priorAuthorizationNumber,
      (currentValue, oldValue) => {
        if (oldValue || !data.encounter?.encounterId) data.isDirty = true;
      }
    );

    watch(
      () => data.encounter?.facilityId,
      (currentValue, oldValue) => {
        if (oldValue || !data.encounter?.encounterId) data.isDirty = true;
      }
    );

    const ifPayersDisabledEnabled = computed(() => {
      return data.encounter?.status == "SubmittedToClearinghouse";
    });

    const ifUpdateSubmitEnabled = computed(() => {
      return (
        data.encounter?.status != "Billed" &&
        data.encounter?.status != "SubmittedToClearinghouse"
        // data.encounter?.status != "BillPatient"
      );
    });

    const ifUpdateEnabled = computed(() => {
      return (
        data.encounter?.status != "Billed" &&
        //data.encounter?.status != "SubmittedToClearinghouse" &&
        data.encounter?.status != "BillPatient"
      );
    });

    const ifSubmitEnabled = computed(() => {
      return (
        data.encounter?.status == "ReadyToBill" ||
        data.encounter?.status == "ReadyToRebill"
      );
    });

    const ifFinishEnabled = computed(() => {
      return (
        data.encounter?.status == "NeedsAttention" ||
        data.encounter?.status == "OnHold" ||
        data.encounter?.status == "ReadyToBill" ||
        !data.encounter?.id
      );
    });

    const ifDeleteEnabled = computed(() => {
      return (
        data.encounter?.status != "Billed" &&
        data.encounter?.status != "SubmittedToClearinghouse" &&
        data.encounter?.status != "BillPatient" &&
        data.encounter?.status
      );
    });

    async function searchPatients(text) {
      const patients = await searchByName({ search: text });
      return patients;
    }

    async function getAllClaims() {
      let request = {
        keyword: "",
        pageNumber: 0,
        pageSize: 100,
        orderBy: ["id"],
        encounterId: data.encounter?.id,
      };

      const res = await searchClaims(request);
      data.claims = res.data;
    }

    const isReadOnly = computed(() => {
      //return data.encounter?.status == "SubmittedToClearinghouse";
      return false;
    });

    const priorAuthorizationNumberMask = reactive({
      mask: "XA*",
      tokens: {
        // matches all alphanumeric characters, and "-"
        A: { pattern: /[a-zA-Z0-9\\-]+/, multiple: true },
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();

      setCurrentPageBreadcrumbs("Encounter Page", [
        { buttonTitle: "Back", path: breadcrumbPath },
      ]);
      getDict();
      data.panel = await getPanel();
      if (route.query.encounterForCopy) {
        data.encounter = (await getEncounter(
          route.query.encounterForCopy
        )) as Encounter;
        data.serviceDates = data.encounter.services.map((p) => {
          return p.serviceDate;
        });
        duplicateEncounter();
        await getMainServicePayers();
        await updatePrimaryPayers();

        const text =
          "Encounter ID " + data.encounter.encounterId + " successfully copied";
        Swal.fire({
          title: text,
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      } else if (props.encounter) {
        data.encounter = props.encounter as Encounter;
        data.serviceDates = data.encounter.services.map((p) => {
          return p.serviceDate;
        });
        await getMainServicePayers();
        //await updatePrimaryPayers();
      } else {
        data.encounter = defaultEncounter();
        data.encounter.patientId = props.patientId;
        const defaultFacility = await getDefaultFacility();

        if (defaultFacility && data.encounter.facilityEncounter) {
          data.encounter.facilityEncounter.facilityBillingProviderId =
            defaultFacility.id;
        }
        data.encounter.primaryPayerId = null;
        data.encounter.secondaryPayerId = null;
        data.encounter.tertiaryPayerId = null;
      }

      if (
        data.encounter?.facility?.id &&
        (!data.encounter?.facility?.npi ||
          !data.encounter?.facility.taxId ||
          !data.encounter?.facility.address)
      ) {
        data.selectedFacilityIncomplete = true;
        if (data.encounter?.facilityEncounter?.attendingProviderId) {
          var attendingProvider = await getProvider(
            data.encounter?.facilityEncounter?.attendingProviderId
          );
          if (attendingProvider && !attendingProvider.npi)
            data.selectedAttendingProviderIncomplete = true;
          showFacilityIncompleteDialog(data.encounter?.facility?.id);
        } else {
          showFacilityIncompleteDialog(data.encounter?.facility?.id);
        }
      }

      if (data.encounter?.facilityEncounter?.attendingProviderId) {
        attendingProvider = await getProvider(
          data.encounter?.facilityEncounter?.attendingProviderId
        );
        if (attendingProvider && !attendingProvider.npi) {
          data.selectedAttendingProviderIncomplete = true;
          showProviderIncompleteDialog(
            data.encounter?.facilityEncounter?.attendingProviderId
          );
        }
      }
      if (props.updateOnly) {
        data.updateOnly = props.updateOnly;
      }
      if (data.encounter?.encounterId) {
        await getAllClaims();
      }
      data.isLoaded = true;
      data.isDirty = false;
    });

    function getDict() {
      data.dict.encounterActions = [
        { code: "copy", description: "Copy" },
        {
          code: "hold",
          description: "Hold",
          statusFrom: ["ReadyToBill", "ReadyToRebill"],
          action: 1,
        },
        {
          code: "removehold",
          description: "Remove Hold",
          statusFrom: ["OnHold"],
          action: 2,
        },
        {
          action: 402,
          code: "void",
          description: "Void",
          statusFrom: ["BillPatient", "SubmittedToClearinghouse"],
        },
      ];
      data.dict.submitAction = {
        code: "submit",
        description: "Submit",
        statusFrom: ["ReadyToBill", "ReadyToRebill"],
        action: 4,
      };
      data.dict.encounterStatusCodes = store.getters.allEncounterStatuses;
    }

    async function encounterValidation() {
      let result = await v$.value.encounter.$validate();

      if (data.encounter?.encounterType == 0) {
        const fe = facilityEncounter.value as any;
        let facilityRes = await fe.v$.encounter.facilityEncounter.$validate();

        if (!facilityRes) {
          result = false;
        }
      }

      if (data.encounter?.encounterType == 1) {
        const pe = professionalEncounter.value as any;
        let professionalRes = await pe.v$.$validate();

        if (!professionalRes) {
          result = false;
        }

        if (pe.isAllDiagnosisUsed().length > 0) {
          result = false;
        }
      }

      // if (!isEffectivePrimaryPayersValid()) {
      //   result = false;
      // }

      if (result) {
        setEncounterServices();
      }

      return result;
    }

    function setFacilityAdditonalInformation() {
      if (data.encounter?.encounterType == 0) {
        const fe = facilityEncounter.value as any;
        fe.avoidNullValues();
      }
    }

    function setEncounterServices() {
      if (
        data.encounter?.encounterType == 0 &&
        data.encounter.facilityEncounter
      ) {
        data.encounter.services = data.encounter.facilityEncounter.services;
        const fe = facilityEncounter.value as any;
        fe.data.isDirty = false;
        data.encounter.professionalEncounter = null;
      }
      if (
        data.encounter?.encounterType == 1 &&
        data.encounter.professionalEncounter
      ) {
        data.encounter.services = data.encounter.professionalEncounter.services;
        const pe = professionalEncounter.value as any;
        pe.data.isDirty = false;
        data.encounter.facilityEncounter = null;
      }
    }

    async function addItem() {
      data.addDisabled = true;
      const validationRes = await encounterValidation();
      if (validationRes) {
        try {
          setFacilityAdditonalInformation();
          const res = await addEncounter(data.encounter);
          data.encounter = await getEncounter(res);
          data.isDirty = false;

          showModal.value = true;

          return res;
        } finally {
          data.addDisabled = false;
        }
      }
      data.addDisabled = false;
      return null;
    }

    async function addRawItem() {
      v$.value.$reset();
      let result = await v$.value.encounter.$validate();
      if (result) {
        data.inProgress = true;
        try {
          setEncounterServices();
          setFacilityAdditonalInformation();
          if (data.encounter?.encounterId) {
            await updateRawEncounter(data.encounter);
            data.encounter = await getEncounter(data.encounter?.id);
            getAllClaims();
            data.isDirty = false;
            v$.value.$reset();
          } else {
            let encounterId = await addRawEncounter(data.encounter);
            data.encounter = await getEncounter(encounterId);
            data.isDirty = false;
            showModal.value = true;
          }
        } finally {
          data.inProgress = false;
        }
      }
    }

    async function updateItem() {
      data.inProgress = true;
      try {
        let success = await updateItemInt();

        if (success) {
          router.push(breadcrumbPath);
        }
      } finally {
        data.inProgress = false;
      }
    }

    async function updateItemInt() {
      const validationRes = await encounterValidation();
      if (validationRes) {
        if (
          data.encounter?.status == "SubmittedToClearinghouse" &&
          !(await checkMaxClaimAmount())
        )
          return;
        setFacilityAdditonalInformation();
        await updateEncounter(data.encounter);
        data.isDirty = false;

        if (data.encounter) {
          data.encounter = await getEncounter(data.encounter.id);

          if (data.encounter?.encounterId) {
            await getAllClaims();
          }

          return true;
        }
      }

      return false;
    }

    async function addEncounterAndClaimItem() {
      const validationRes = await encounterValidation();
      if (validationRes) {
        if (!(await checkMaxClaimAmount())) return;
        data.inProgress = true;
        try {
          setFacilityAdditonalInformation();
          const encounterId = await addEncounter(data.encounter);
          data.isDirty = false;

          if (encounterId) {
            await submitClaim(encounterId);
          }

          data.encounter = await getEncounter(encounterId);
          showModal.value = true;
        } finally {
          data.inProgress = false;
        }
      }
    }

    async function updateEncounterAndCreateClaim() {
      const validationRes = await encounterValidation();
      if (validationRes) {
        if (!(await checkMaxClaimAmount())) return;
        data.inProgress = true;
        try {
          const validationRes = await encounterValidation();
          if (validationRes) {
            setFacilityAdditonalInformation();
            await updateEncounter(data.encounter);
            data.isDirty = false;

            if (data.encounter) {
              if (await submitClaim(data.encounter?.id))
                router.push(breadcrumbPath);
            }
          }
        } finally {
          data.inProgress = false;
        }
      }
    }

    async function checkMaxClaimAmount() {
      const check = await checkEncounterMaxClaimAmount({
        encounter: data.encounter,
      });

      if (!check) {
        const res = await Swal.fire({
          title:
            "The Total Charges for this claim exceeds the Max Claim Amount for this Insurance. Are you sure you want to Proceed?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "Cancel",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        });
        if (!res.isConfirmed) return false;
      }

      return true;
    }

    async function submitClaim(encounterId) {
      const res = await postAction({
        encountersId: [encounterId],
        encounterAction: 4,
      });

      if (res.errors.length != 0) {
        Swal.fire({
          title: "Info!",
          text: res.errors[0],
          icon: "info",
          customClass: {
            confirmButton: "ab-button",
          },
        });
        return false;
      }
      return true;
    }

    async function submit() {
      data.inProgress = true;
      try {
        if (!(await checkMaxClaimAmount())) return;
        await sendAction(data.dict.submitAction);
      } finally {
        data.inProgress = false;
      }
    }

    async function deleteItem() {
      Swal.fire({
        title: "Are you sure you want to delete this Encounter?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (data.encounter) {
            const deleteRes = await deleteEncounter(data.encounter.id);
            if (deleteRes) {
              ctx.emit("itemChanged");
              router.go(-1);
              const text =
                "Encounter ID " +
                data.encounter?.encounterId +
                " successfully deleted";
              Swal.fire({
                title: text,
                confirmButtonText: "Ok!",
                icon: "success",
                customClass: {
                  confirmButton: "ab-button",
                },
              });
            }
          }

          //router.push({ name: "serviceManagement" });
        }
      });
    }

    // function isEffectivePrimaryPayersValid() {
    //   return (
    //     data.effectivePrimaryPayers?.items.length <= 1 ||
    //     !data.effectivePrimaryPayers
    //   );
    // }

    const localActions = ["copy", "void"];
    async function localActionHandler(action) {
      if (action == "copy" && data.encounter) {
        Swal.fire({
          title: "Are you sure you want to copy this Encounter?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then((result) => {
          if (result.isConfirmed && data.encounter) {
            router.push({
              path: "/serviceManagement/encounterNew",
              query: {
                encounterForCopy: data.encounter.id,
                breadcrumb: breadcrumbPath,
              },
            });
          }
        });
      }
      if (action === "void" && data.encounter) {
        if (
          await checkEncounterHasOpenClaims({
            id: data.encounter.id,
          })
        ) {
          Swal.fire({
            title: "Error!",
            text: "Encounter cannot be Voided. 1 or more active Claims are associated to this Encounter.",
            icon: "error",
            customClass: {
              confirmButton: "ab-button",
            },
          });
          return;
        }
        // TODO: void encounter
        const check = await checkEncounterTransactions({
          id: data.encounter.id,
        });
        if (check) {
          Swal.fire({
            title:
              "You are voiding an Encounter which has associated payments/adjustments. Would you like to reverse these payments/adjustments?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            allowOutsideClick: false,
            customClass: {
              cancelButton: "ab-button-secondary",
              confirmButton: "ab-button",
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              voidEncounter(data.encounter);
            }
          });
        } else {
          voidEncounter(data.encounter);
        }
      }
    }

    function duplicateEncounter(): boolean {
      if (data.encounter) {
        data.encounter.id = "";
        data.encounter.encounterId = "";
        data.encounter.status = "";
        if (data.encounter.facilityEncounter) {
          data.encounter.facilityEncounter.services = JSON.parse(
            JSON.stringify(data.encounter.services)
          );
        }
        if (data.encounter.professionalEncounter) {
          data.encounter.professionalEncounter.services = JSON.parse(
            JSON.stringify(data.encounter.services)
          );
        }
        data.claims = [];
        return true;
      }
      return false;
    }

    async function sendAction(action) {
      if (localActions.includes(action.code)) {
        localActionHandler(action.code);
        return;
      }

      const res = await postAction({
        encountersId: [data.encounter?.id],
        encounterAction: action.action,
      });
      if (!res) return;
      let actionsText = {
        0: "approved",
        1: "put on hold",
        2: "removed from Hold",
        3: "copied",
        4: "submitted",
      };
      let actionText = actionsText[action.action];
      let text = "";
      if (res.errors && res.errors.length > 0) {
        res.errors.forEach((error) => {
          text = text + "\n" + error;
        });
        Swal.fire({
          title: "Error!",
          text,
          icon: "error",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      } else {
        text = `Encounter was successfully ${actionText}.`;
        Swal.fire({
          title: text,
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      }
      data.encounter = await getEncounter(data.encounter?.id);
    }

    function checkDisplay(item, action) {
      if (action.action === 402) {
        if (item.status == "Voided") return false;
      }
      return true;
    }

    async function voidEncounter(item) {
      const res = await voidAction({
        id: item.id,
        entryDate: new Date(),
      });
      if (res) {
        Swal.fire({
          title: "Encounter " + item.encounterId + " was successfully Voided",
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
        console.log("void encounter");
        data.encounter = await getEncounter(data.encounter?.id);
      }
    }

    async function setType(type) {
      if (data.encounter) data.encounter.encounterType = type;
      if (type == 0) {
        data.serviceDates = data.encounter?.facilityEncounter?.services.map(
          (c) => {
            return c.serviceDate;
          }
        );
      } else {
        data.serviceDates = data.encounter?.professionalEncounter?.services.map(
          (c) => {
            return c.serviceDate;
          }
        );
      }
      await getMainServicePayers();
      await updatePrimaryPayers();
    }

    async function facilityChanges(facilityId) {
      if (!(data.encounter && facilityId)) return;
      const facility = await getFacility(facilityId);
      if (
        !facility.npi ||
        facility.npi == "" ||
        !facility.taxId ||
        facility.taxId == "" ||
        !facility.address ||
        facility.address == ""
      ) {
        data.selectedFacilityIncomplete = true;
        showFacilityIncompleteDialog(facilityId);
      } else {
        data.selectedFacilityIncomplete = false;
      }
      const profComponent = professionalEncounter.value as any;
      if (profComponent) {
        profComponent.setDefaults(facility);
      }

      const facComponent = facilityEncounter.value as any;
      if (facComponent) {
        facComponent.setDefaults(facility);
      }
    }

    async function showFacilityIncompleteDialog(facilityId) {
      if (data.selectedFacilityIncomplete) {
        Swal.fire({
          title:
            "This Facility record is incomplete. Please add data for all mandatory fields before using this Facility",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Ok",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then((result) => {
          data.isDirty = false;
          if (result.isConfirmed) {
            router.push({
              name: "facilityPageAfterEncounter",
              params: {
                facilityId: facilityId.toString(),
                encounterId: data.encounter?.id?.toString(),
              },
            });
          }
        });
      }
      return;
    }

    async function showProviderIncompleteDialog(providerId) {
      if (
        data.selectedFacilityProviderIncomplete ||
        data.selectedAttendingProviderIncomplete
      ) {
        Swal.fire({
          title:
            "This Provider record is incomplete. Please add data for all mandatory fields before using this Provider",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Ok",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then((result) => {
          data.isDirty = false;
          if (result.isConfirmed) {
            router.push({
              name: "providerPageAfterEncounter",
              params: {
                providerId: providerId.toString(),
                encounterId: data.encounter?.id?.toString(),
              },
            });
          }
        });
      }
      return;
    }

    async function serviceDateChanged(dates) {
      data.serviceDates = dates;
      await getMainServicePayers();
      await updatePrimaryPayers();
    }

    async function updatePrimaryPayer() {
      if (data.encounter) {
        await nextTick();
        if (!data.encounter?.primaryPayerId) {
          data.encounter.primaryPayer = null;
          data.encounter.secondaryPayerId = null;
          data.encounter.tertiaryPayerId = null;
        } else {
          data.encounter.primaryPayer = data.dict.patientPayers.find((item) => {
            return item.id == data.encounter?.primaryPayerId;
          });
        }

        if (
          !data.encounter?.secondaryPayerId ||
          !data.encounter?.primaryPayerId
        ) {
          data.encounter.secondaryPayer = null;
        } else {
          data.encounter.secondaryPayer = data.dict.patientPayers.find(
            (item) => {
              return item.id == data.encounter?.secondaryPayerId;
            }
          );
        }

        if (
          !data.encounter?.tertiaryPayerId ||
          !data.encounter?.primaryPayerId
        ) {
          data.encounter.tertiaryPayer = null;
        } else {
          data.encounter.tertiaryPayer = data.dict.patientPayers.find(
            (item) => {
              return item.id == data.encounter?.tertiaryPayerId;
            }
          );
        }
      }
    }

    function getPatientPayersList(level) {
      return data.dict.patientPayers?.filter((item) => {
        return (
          ((item.id != data.encounter?.primaryPayerId || level == 0) &&
            (item.id != data.encounter?.secondaryPayerId || level == 1) &&
            (item.id != data.encounter?.tertiaryPayerId || level == 2)) ||
          item.id == null
        );
      });
    }

    async function selectPatient() {
      await getMainServicePayers();
      await updatePrimaryPayers();
    }

    async function getMainServicePayers() {
      if (!data.encounter?.patientId) return;

      const nullDates = data.serviceDates?.filter((item) => {
        return item == null;
      });
      if (
        !data.encounter ||
        !data.encounter?.patientId ||
        !data.serviceDates ||
        nullDates.length != 0
      )
        return;

      let request = {
        patientId: data.encounter?.patientId,
        dates: data.serviceDates,
      };
      const res = await effectivePayers(request);
      data.effectivePrimaryPayers = res.items.find((c) => {
        return c.priority == 0;
      });
      let effectiveSecondaryPayers = res.items.find((c) => {
        return c.priority == 1;
      });
      let effectiveTertiaryPayers = res.items.find((c) => {
        return c.priority == 2;
      });

      const firstService = data.serviceDates[0];
      if (!firstService) return;

      data.mainServicePayers.primaryPayer =
        data.effectivePrimaryPayers.items.find((c) => {
          return c.dates.find((p) => {
            return new Date(p).getTime() == new Date(firstService).getTime();
          });
        });

      //secondary
      data.mainServicePayers.secondaryPayer =
        effectiveSecondaryPayers.items.find((c) => {
          return c.dates.find((p) => {
            return new Date(p).getTime() == new Date(firstService).getTime();
          });
        });

      //tertiary
      data.mainServicePayers.tertiaryPayer = effectiveTertiaryPayers.items.find(
        (c) => {
          return c.dates.find((p) => {
            return new Date(p).getTime() == new Date(firstService).getTime();
          });
        }
      );

      updatePayerListFromMainServicePayers();
    }

    async function updatePayerListFromMainServicePayers() {
      if (!data.encounter?.patientId) return;

      data.dict.patientPayers = await patientPayersByPatient({
        patientId: data.encounter?.patientId,
      });

      const mainServicePayers = [
        data.mainServicePayers.primaryPayer,
        data.mainServicePayers.secondaryPayer,
        data.mainServicePayers.tertiaryPayer,
      ];

      mainServicePayers.forEach((payer) => {
        if (
          payer?.patientPayer &&
          !data.dict.patientPayers.find((item) => {
            return item.id == payer.patientPayer.id;
          })
        )
          data.dict.patientPayers.push(payer.patientPayer);
      });
    }

    async function updatePrimaryPayers() {
      if (!data.encounter) return;
      if (ifPayersDisabledEnabled.value) return;

      const servicePayer = data.mainServicePayers.primaryPayer;
      if (servicePayer?.patientPayer) {
        data.encounter.primaryPayer = servicePayer.patientPayer;
        data.encounter.primaryPayerId = servicePayer.patientPayer.id;
      } else {
        data.encounter.primaryPayer = null;
        data.encounter.primaryPayerId = null;
      }
      //secondary
      const secondaryPayer = data.mainServicePayers.secondaryPayer;
      if (secondaryPayer?.patientPayer) {
        data.encounter.secondaryPayer = secondaryPayer.patientPayer;
        data.encounter.secondaryPayerId = secondaryPayer.patientPayer.id;
      } else {
        data.encounter.secondaryPayer = null;
        data.encounter.secondaryPayerId = null;
      }
      //tertiary
      const tertiaryPayer = data.mainServicePayers.tertiaryPayer;
      if (tertiaryPayer?.patientPayer) {
        data.encounter.tertiaryPayer = tertiaryPayer.patientPayer;
        data.encounter.tertiaryPayerId = tertiaryPayer.patientPayer.id;
      } else {
        data.encounter.tertiaryPayer = null;
        data.encounter.tertiaryPayerId = null;
      }
    }

    function handleCopyEncounter() {
      duplicateEncounter();
      v$.value.$reset();
      showModal.value = false;
    }

    function handleNewEncounter() {
      router.replace({
        path: "encounterNew",
        query: { breadcrumb: breadcrumbPath },
      });
      ctx.emit("newEncounter");
    }

    function handleBack() {
      router.push(breadcrumbPath);
    }

    function defaultEncounter() {
      return {
        id: "",
        status: null,
        effectiveDate: new Date(),
        services: [],
        patientId: undefined,
        facilityEncounter: {
          services: [],
          modifiersList: null,
          typeOfFacility: null,
          typeOfCare: null,
          frequency: null,
          attendingProviderId: null,
          admissionSource: null,
          dischargeStatus: null,
          providerAcceptAssignment: true,
          releaseOfInformation: true,
          principalDiagnosis: null,
          admittingDiagnosis: null,
          diagnosisList: null,
          facilityBillingProviderId: null,
          admissionType: null,
          admissionDate: null,
          admissionHour: null,
          additionalInformation: null,
        },
        professionalEncounter: {
          claimNote: null,
          billingProviderId: null,
          renderingProviderId: null,
          referringProviderId: null,
          services: [],
          modifiersList: null,
          providerAcceptAssignment: true,
          patientsSignatureOnFile: true,
          insuredsSignatureOnFile: true,
          additionalInformation: null,
        },

        encounterType: 0,
      };
    }

    function actionEnabled(action) {
      const fe = facilityEncounter.value as any;
      const pe = professionalEncounter.value as any;
      if (
        action.code == "copy" &&
        (data.isDirty || (fe && fe.data.isDirty) || (pe && pe.data.isDirty))
      ) {
        return true;
      }
      return false;
    }

    async function cancel() {
      router.push(breadcrumbPath);
    }

    return {
      actionEnabled,
      addEncounterAndClaimItem,
      addItem,
      addRawItem,
      cancel,
      checkDisplay,
      data,
      deleteItem,
      facilityChanges,
      facilityEncounter,
      getDict,
      getPatientPayersList,
      handleBack,
      handleCopyEncounter,
      handleNewEncounter,
      ifDeleteEnabled,
      ifUpdateSubmitEnabled,
      ifUpdateEnabled,
      ifSubmitEnabled,
      ifFinishEnabled,
      ifPayersDisabledEnabled,
      organizationId,
      submit,
      professionalEncounter,
      searchPatients,
      sendAction,
      serviceDateChanged,
      setType,
      selectPatient,
      showModal,
      updateEncounterAndCreateClaim,
      updateItem,
      updatePrimaryPayers,
      updatePrimaryPayer,
      isReadOnly,
      priorAuthorizationNumberMask,
      v$,
    };
  },
});
